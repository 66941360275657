import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ReactEcharts from "echarts-for-react";

function SaasSubsChart(props) {
  const saasPositiveNagetiveChg = props.saasPnChg ? props.saasPnChg : [];
  const { saasTableData } = useSelector((s) => s);
  const [options, setOptions] = useState({
    legend: {
      show: true,
      textStyle: {
        color: "white",
      },
    },
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        color: "#77778e",  
        rotate: 28, 
        // interval: 60,
        fontSize: 11,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
          pixelRatio: 2,
          backgroundColor: '#32323e',
        },
      },
    },
    animation: false,
    yAxis: [
      {
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(80, 80, 80, 0.8)",
            width: 1,
          },
        },
      },
      {
        splitLine: {
          show: false,
        },
      },],
    series: [
      {
        name: "",
        data: [],
        type: "line",
        yAxisIndex: 0,
        itemStyle: {
          color: 'rgb(0, 0, 255)',
        },
        symbol: 'none',
        color: 'rgb(0, 0, 255)',
      },
      {
        name: "",
        data: [],
        type: "line",
        yAxisIndex: 1,
        lineStyle: {
          color: 'rgb(255, 0, 0)',
        },
        symbol: 'none',
        color: 'rgb(255, 0, 0)',
      },
    ],
  });
  var dateLabels = [];
  var positiveChanges = [];
  var negativeChanges = [];
  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = '/'
    }
    var newArray = [];
    saasPositiveNagetiveChg.map((el) => {
      const objlen = Object.keys(el).length;
      for (let i = 0; i < objlen; i++) {
        const finalObj = Object.values(el)[i];
        newArray.push(finalObj);
        dateLabels.push(Object.keys(el)[i])
      }
    });
    newArray.map((el) => {
      positiveChanges.push(el.positives);
      negativeChanges.push(el.negatives);
    });
    const series = [
      {
        name: "# of positive changes",
        type: "bar",
        itemStyle: {
          color: 'rgb(0, 0, 255)',
        },
        data: positiveChanges,
        yAxisIndex: 0,
        symbol: 'none',
        color: 'rgb(0, 0, 255)',
      },
      {
        name: "# of negative changes",
        type: "line",
        lineStyle: {
          color: 'rgb(255, 0, 0)',
        },
        data: negativeChanges,
        yAxisIndex: 1,
        symbol: 'none',
        color: 'rgb(255, 0, 0)',
      },
    ];
    const xAxisLabels = {
      type: "category",
      data: dateLabels,
    }
    setOptions({ ...options, labels: dateLabels, xAxis: xAxisLabels, series: series });
  }, [saasTableData]);

  return (
    <div id="chart">
      <ReactEcharts option={options} style={{ height: '400%', width: '100%', }} />
    </div>
  );
}

export default SaasSubsChart;

import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import BounceLoader from "react-spinners/BounceLoader";
import { Dropdown } from "react-bootstrap";
import { Card } from "react-bootstrap";
import {
  Chart as JobChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import JobChart from "react-apexcharts";
import "./jobs.css";
import {
  jobsSeries,
  jobsOptions,
  getJobSkillTrends,
} from "./../../services/skillDataService.js";
import {
  reloadIfTokenExpire,
  defaultCompanyName,
  defaultCompanyTicker,
  checkExpiredPeriod,
} from "./../../services/common.js";
import { useSelector, useDispatch } from "react-redux";
import ReactEcharts from "echarts-for-react";

JobChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const override = css`
  display: block;
  margin: 0 auto;
`;

const eChartOption = {
  legend: {
    show: true,
    textStyle: {
      color: "white",
    },
  },
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      color: "#77778e",  
      rotate: 28, 
      // interval: 60,
      fontSize: 11,
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
    },
  },
  toolbox: {
    show: true,
    feature: {
      saveAsImage: {
        show: true,
        title: "Save As Image",
        pixelRatio: 2,
        backgroundColor: '#32323e',
      },
    },
  },
  animation: false,
  yAxis: [
    {
      min: 0,
      max: 0,
    },
    {
      min: 0,
      max: 0,
    },
  ],
  series: [
    {
      name: "",
      data: [],
      type: "line",
      lineStyle: {
        color: 'rgb(22, 202, 242)',
      },
    },
    {
      name: "",
      data: [],
      type: "line",
      lineStyle: {
        color: 'rgb(255, 255, 0)',
      },
    },
  ],
};

function Jobs(props) {
  const [loader, setLoader] = useState(false);
  const [selectValue, setSelectValue] = useState("All");
  const [firstTime, setFirstTime] = useState(true);
  const [jobPeriod, SetjobPeriod] = useState(true);
  const dispatch = useDispatch();
  const { jobTrends, jobsFilterParams, suggestionsList } = useSelector(
    (s) => s
  );
  const [jobChartOptions, setJobChartOptions] = useState({ ...jobsOptions });
  const [jobsData, setJobsData] = useState({ ...eChartOption });
  const [loading, setLoading] = useState(false);
  const [wait, setWait] = useState(true);
  const [numberOfMonthsData, setNumberOfMonthsData] = useState("All");
  const [initialLoading, setInitialLoading] = useState(props.initialLoading);

  const callJobsData = (jobsFilterParams) => {
    setLoading(true);
    getJobSkillTrends(jobsFilterParams).then(function (response) {
      checkExpiredPeriod(response?.data?.STATUS, response?.data?.message);
      dispatch({ type: "JOB_TRENDS", jobTrends: response.data });
      setLoading(false);
    }).catch((error)=>{
      reloadIfTokenExpire(error.response.data);
    })
  };

  const handleFilterChange = (filter) => {
    
    dispatch({
      type: "JOBS_FILTER_PARAMS",
      jobsFilterParams: { ...jobsFilterParams, filter: filter },
    });
  };

  const handleFilterShowChildChange = (showChild) => {
    dispatch({
      type: "JOBS_FILTER_PARAMS",
      jobsFilterParams: { ...jobsFilterParams, showChild },
    });
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = '/'
    }
    if (!initialLoading) {
      callJobsData(jobsFilterParams);
    }
    setInitialLoading(false);
  }, [jobsFilterParams]);

  const dispatchJobFilter = (dCompanyName, dCompanyTicker) => {
    dispatch({
      type: "JOBS_FILTER_PARAMS",
      jobsFilterParams: {
        ...jobsFilterParams,
        companyName: dCompanyName,
        ticker: dCompanyTicker,
        // ticker: defaultCompanyTicker,
      },
    });
  };

  useEffect(() => {
    setLoading(true)
    if (suggestionsList.length) {
      for (let i = 0; i < suggestionsList.length; i++) {
        const element = suggestionsList[i];
        // Trial User
        if (element.type === "jobs" && element.trialAccess === true) {
          dispatchJobFilter(element.name, element.ticker);
          setLoading(false)
          break;
        }
        // Regular User
        if (element.type === "jobs" && element.trialAccess === 0 && element.isTrial === false) {
          dispatchJobFilter(defaultCompanyName, defaultCompanyTicker);
          setLoading(false)
          break;
        }
      }
    }
  }, [suggestionsList]);

  function showSelectedMonthsData(month){
    setNumberOfMonthsData(month);
    setLoader(true);
    const currentDate = new Date();
    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(currentDate.getMonth() - month);

    const total_job_openings_selected_months_data = {};
    const new_jobs_selected_months_data = {};

    // Iterate over the original data and filter entries within the last three months
    for (const date in (jobTrends.total_job_openings)) {
      if (new Date(date) >= threeMonthsAgo && new Date(date) <= currentDate) {
        total_job_openings_selected_months_data[date] = (jobTrends.total_job_openings)[date];
      }
    }

    for (const date in (jobTrends.num_new_jobs_past_84_days)) {
      if (new Date(date) >= threeMonthsAgo && new Date(date) <= currentDate) {
        new_jobs_selected_months_data[date] = (jobTrends.num_new_jobs_past_84_days)[date];
      }
    }

    let series1 = [];
    let yAxisMinMax1 = [];
    const options1 = { ...jobsData };
    if (jobsFilterParams.filter == "all") {
      series1 = [
        {
          name: "# of total job openings",
          data: Object.values(total_job_openings_selected_months_data),
          type: 'line',
          lineStyle: {
            color: 'rgb(22, 202, 242)',
          },
          yAxisIndex: 0,
          symbol: "none",
          color: 'rgb(22, 202, 242)',
        },
        {
          name: "# of new job openings (84 day trailing sum)",
          data: Object.values(new_jobs_selected_months_data),
          type: 'line',
          lineStyle: {
            color: 'rgb(255, 255, 0)',
          },
          yAxisIndex: 1,
          symbol: "none",
          color: 'rgb(255, 255, 0)',
        },
      ];
      yAxisMinMax1 = [
        {
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(80, 80, 80, 0.8)",
              width: 1,
            },
          },
        }, 
        {
          splitLine: {
            show: false,
          },
        },
      ];
    } else {
      series1 = [
        {
          name: "# of total job openings",
          data: Object.values(total_job_openings_selected_months_data),
          type: 'line',
          lineStyle: {
            color: 'rgb(22, 202, 242)',
          },
          yAxisIndex: 0,
          symbol: "none",
          color: 'rgb(22, 202, 242)',
        },
        {
          name: "# of new job openings (84 day trailing sum)",
          data: Object.values(new_jobs_selected_months_data),
          type: 'line',
          lineStyle: {
            color: 'rgb(255, 255, 0)',
          },
          symbol: "none",
          color: 'rgb(255, 255, 0)'
        },
      ];
      yAxisMinMax1 = [{
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(80, 80, 80, 0.8)",
            width: 1,
          },
        },
      }];
    }
    const jobsDates = Object.keys(new_jobs_selected_months_data);
    options1.xAxis.data = jobsDates;
    options1.series = series1;
    options1.yAxis = yAxisMinMax1;
    setJobsData(options1);
    setTimeout(() => {
      setLoader(false);
    }, 100)
  }

  useEffect(() => {
    if ("total_job_openings" in jobTrends) {
      reloadIfTokenExpire(jobTrends);
      if(numberOfMonthsData == 12 || numberOfMonthsData == 36 || numberOfMonthsData == 60) {
        showSelectedMonthsData(numberOfMonthsData);
      } else {
        showData("All")
      }
    }
  }, [jobTrends]);


  const showData = (eventkey) => {
    setSelectValue(eventkey)
    if (eventkey == "All") {
      setNumberOfMonthsData('All')
      setLoader(true)
      let series2 = [];
      let yAxisMinMax2 = [];
      const options2 = { ...jobsData };
      if (jobsFilterParams.filter == "all") {
        series2 = [
          {
            name: "# of total job openings",
            data: Object.values(jobTrends.total_job_openings),
            type: 'line',
            lineStyle: {
              color: 'rgb(22, 202, 242)',
            },
            symbol: "none",
            yAxisIndex: 0,
            color: 'rgb(22, 202, 242)',
          },
          {
            name: "# of new job openings (84 day trailing sum)",
            data: Object.values(jobTrends.num_new_jobs_past_84_days),
            type: 'line',
            lineStyle: {
              color: 'rgb(255, 255, 0)',
            },
            symbol: "none",
            yAxisIndex: 1,
            color: 'rgb(255, 255, 0)',
          },
        ];
        yAxisMinMax2 = [
          {
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(80, 80, 80, 0.8)",
              width: 1,
            },
          },
        }, 
        {
          splitLine: {
            show: false,
          },
        },];
      } else {
        series2 = [
          {
            name: "# of total job openings",
            data: Object.values(jobTrends.total_job_openings),
            type: 'line',
            lineStyle: {
              color: 'rgb(22, 202, 242)',
            },
            symbol: "none",
            yAxisIndex: 0,
            color: 'rgb(22, 202, 242)',
          },
          {
            name: "# of new job openings (84 day trailing sum)",
            data: Object.values(jobTrends.num_new_jobs_past_84_days),
            type: 'line',
            lineStyle: {
              color: 'rgb(255, 255, 0)',
            },
            symbol: "none",
            color: 'rgb(255, 255, 0)',
          },
        ];
        yAxisMinMax2 = [{
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(80, 80, 80, 0.8)",
              width: 1,
            },
          },
        }];
      }
      const jobsDates = Object.keys(jobTrends.num_new_jobs_past_84_days);
      options2.yAxis = yAxisMinMax2;
      options2.xAxis.data = jobsDates;
      options2.series = series2;
      setJobsData(options2);
      setTimeout(() => {
        setLoader(false);
      }, 100)
    }else if(eventkey=="Last 1 year"){
      showSelectedMonthsData(12);
    }else if(eventkey=="Last 3 year"){
      showSelectedMonthsData(36);
    }else if(eventkey=="Last 5 year"){
      showSelectedMonthsData(60);
    }
  }
  setTimeout(() => {
    setWait(false);
  }, 100);

  return (
    <div>
      <Card className="box">
        <Card.Body>
          <Card.Title>
            <div className="skill-data-header-wrapper">
              <h2 className="heading">
                {jobsFilterParams.companyName && (
                  <div>
                    <span className="text-capitalize">
                      {jobsFilterParams.companyName}
                    </span>
                    <div className="text-muted xs-small">
                      Number of total and new job openings (trailing 84 day sum)
                    </div>
                  </div>
                )}
              </h2>
              <div className="header-right">

                <div className="skill-data-header m-2">
                  <Dropdown
                    className="dropdown-select"
                    onSelect={(showChild) =>
                      handleFilterShowChildChange(showChild)
                    }
                  >
                    <Dropdown.Toggle
                      className="dropdown-select"
                      disabled={jobsFilterParams.ticker || jobsFilterParams.companyName ? false : true}
                    >
                      {jobsFilterParams.showChild == "true"
                        ? "Include: acquired companies"
                        : "Exclude: acquired companies"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu >
                      {jobsFilterParams.showChild
                        !== "true" ? <Dropdown.Item eventKey="true">Include: acquired companies</Dropdown.Item> : <Dropdown.Item eventKey="false">Exclude: acquired companies</Dropdown.Item>}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="skill-data-header m-2">
                  <Dropdown
                    className="dropdown-select"
                    onSelect={(filter) => handleFilterChange(filter)}
                  >
                    <Dropdown.Toggle
                      className="dropdown-select"
                      disabled={jobsFilterParams.ticker || jobsFilterParams.companyName? false : true}
                    >
                      {jobsFilterParams.filter ? (
                        <span className="job-type">
                          {firstTime ? 'Job type' : jobsFilterParams.filter?.charAt(0).toUpperCase() + jobsFilterParams.filter?.slice(1)}
                        </span>
                      ) : (
                        "Choose type.."
                      )}
                    </Dropdown.Toggle>

                    <Dropdown.Menu onClick={() => setFirstTime(false)}>
                      <Dropdown.Item eventKey="all">All</Dropdown.Item>
                      <Dropdown.Item eventKey="sales">Sales</Dropdown.Item>
                      <Dropdown.Item eventKey="engineering">
                        Engineering
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="customer success">
                        Customer Success
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="skill-data-header m-2">
                      <Dropdown onSelect={showData}  className="dropdown-select">
                        <Dropdown.Toggle  className="dropdown-select">
                        <span> {selectValue}</span>
                        {/* {!loader ? <span> {selectValue}</span> :<div class="spinner-border spinner-border-sm" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>} */}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        <Dropdown.Item
                            eventKey="All"
                          >
                            All
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Last 1 year"
                          >
                            Last 1 year
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Last 3 year"
                          >
                            Last 3 year
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="Last 5 year"
                          >
                            Last 5 year
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
              </div>
            </div>
          </Card.Title>

          <div className="skill-data-chart">
            {loading && (
              <BounceLoader color={"#36D7B7"} css={override} size={50} />
            )}
            {!loading && (
              <div key={loader} style={{ width: "100%", height: "400px" }}>
                <ReactEcharts option={jobsData} style={{ height: '100%', width: '100%', }} />
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}

export default Jobs;

import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Card, Button } from "react-bootstrap";
import Select from "react-select";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./HistoricTable.css";
import BounceLoader from "react-spinners/BounceLoader";
import { createCsv } from "./../../services/historicDataService";
import { reloadIfTokenExpire } from "../../services/common";
import InstallsAndDownloadsChart from "../InstalsAndDownloads/InstallsAndDownloadsChart";
import { historicInstallsAndDownloadData, installsAndDownloadDataList } from "../../services/installsAndDownloadsServices";
const override = css`
  display: block;
  margin: 0 auto;
`;

function InstallsAndDownloadsTable() {
  const [isLoading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [installsAndDownloadsList, setInstallsAndDownloadsList] = useState([]);
  const [installsAndDownloadsName, setInstallsAndDownloadsName] = useState(installsAndDownloadsList[0]);

  let columns = [
    {
      dataField: "snapshot_date",
      text: "Snapshot Date",
      sort: true,
      headerStyle: (col, colIdx) => {
        return { width: "200px" };
      },
    },
    { dataField: "moving_84_day_avg_num_installs", text: "Number of Installs (84 day moving average)", sort: true },
    { dataField: "num_new_installs", text: "Number of New job", sort: true },
  ];

  const getUpworkData = (upwork) => {
    setInstallsAndDownloadsName(upwork);
    setLoading(true);
    historicInstallsAndDownloadData(upwork)
      .then((res) => {
        reloadIfTokenExpire(res.data);
        if (res.data !== "No Response") {
          const data = res.data;
          data.forEach(obj => {
            if (obj.moving_84_day_avg_num_installs !== null) {
              obj.moving_84_day_avg_num_installs = Math.round(obj.moving_84_day_avg_num_installs);
            }
          });
          setFinalData(data);
          setLoading(false);
        } else {
          console.log("Install and Downloads Not Found");
        }
      })
      .catch((err) => {
        reloadIfTokenExpire(err.response.data);
        console.log("Install and Downloads Not Found");
      });
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = '/'
    }
    let isMounted = true; // Flag to track component's mount state

    const fetchUpworkKeysData = () => {
        installsAndDownloadDataList()
        .then((data) => {
          reloadIfTokenExpire(data.data);
          if (isMounted) {
            // Check if component is still mounted before updating state
            setInstallsAndDownloadsList(data.data.VisualStudios_list);
          }
        })
        .catch((error) => {
          reloadIfTokenExpire(error.response.data);
          console.error("Error fetching Install and Downloads data:", error);
        });
    };
    
    // Call the fetch functions and set up the cleanup logic
    Promise.all([fetchUpworkKeysData()]).then((e) => {
      if (isMounted) {
        // Check if component is still mounted before executing additional logic
        getUpworkData("databricks");
      }
    });

    // Cleanup function to handle component unmount
    return () => {
      isMounted = false; // Update the flag to indicate that component is unmounted
    };
  }, []);

  const handleDownload = (csvFileDataObj) => {
    csvFileDataObj.sort(function (a, b) {
      return new Date(b.snapshot_date) - new Date(a.snapshot_date);
    });
    createCsv(csvFileDataObj, {
      skillKey: { name: installsAndDownloadsName, type: "install-and-downloads" },
    });
  };

  const handleSelectChange = (upwork) => {
    setInstallsAndDownloadsName(upwork.value);
    getUpworkData(upwork.value);
  };

  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    sizePerPage: 50,
  });

  return (
    <>
      <Card className="box">
        <Card.Body>
          <Card.Title>
            <div className="skill-data-header-wrapper">
              <h2 className="heading">
                {true && (
                  <div>
                    <div>
                      <span className="text-capitalize">{installsAndDownloadsName}</span>
                    </div>
                    <div className="text-muted xs-small">
                    Installs and Downloads : Number of Installs (84 day moving average)
                    </div>
                  </div>
                )}
              </h2>
              <div className="macro-data-header">
                <Select
                  className="text-muted xs-small"
                  id="indudstrydropdown"
                  options={installsAndDownloadsList.map((e) => ({
                    value: e,
                    label: e,
                    key: e,
                  }))}
                  value={{ value: installsAndDownloadsName, label: installsAndDownloadsName }}
                  onChange={handleSelectChange}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      minWidth: "200px",
                      backgroundColor: "#32323e",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "#77778e",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "#77778e",
                    }),
                    option: (provided) => ({
                      ...provided,
                      backgroundColor: "#32323e",
                    }),
                  }}
                />
              </div>
            </div>
          </Card.Title>
        </Card.Body>
        {isLoading ? (
          <BounceLoader color={"#36D7B7"} css={override} size={50} />
        ) : (
          <InstallsAndDownloadsChart data={finalData} />
        )}
      </Card>
      <div style={{ marginTop: 30 }}>
        <Card className="box">
          {
            <h2 className="heading relative">
              <div>Data for {installsAndDownloadsName}</div>
              <Button
                onClick={() => handleDownload(finalData)}
                className={`position-right custom-dark-mode ${
                  !finalData.length ? "disabled" : ""
                }`}
              >
                Download Data
              </Button>{" "}
            </h2>
          }
          {isLoading && (
            <BounceLoader color={"#36D7B7"} css={override} size={50} />
          )}
          {!isLoading && (
            <BootstrapTable
              key="saasTable"
              id="saasTable"
              name="saasTable"
              keyField="date"
              data={finalData}
              columns={columns}
              pagination={pagination}
              sort={{ dataField: "change_date", order: "desc" }}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default InstallsAndDownloadsTable;

export const accessToken = window.localStorage.getItem('accessToken');
import toast from "react-hot-toast";

export const reloadIfTokenExpire = (responseData) => {
    if (("ERROR" in responseData) || (responseData.detail == "Invalid token."))  {
        if (window.localStorage.getItem('reload_count')) {
            toast("Access token is expired!");
            window.localStorage.removeItem('accessToken')
            window.localStorage.removeItem('reload_count')
            window.localStorage.removeItem('revelera_user')
            window.location.href = '/'
        } else {
            window.localStorage.setItem('reload_count', 1)
            window.location.reload();
        }
    }
}

export const checkExpiredPeriod = (status, msg) => {
    if (status === "KO") {
        if (msg === "TRIAL_EXPIRED") {
            location.href = "/expired";
        }
    }
}
export const defaultCompanyName = 'google';
export const defaultCompanyTicker = 'GOOG';
export const defaultSkillSearchValue = 'php';
export const defaultSaasSearchValue = 'akamai';
export const defaultAlertSearchValue = 'amazon';

import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./AlertTable.css";
import BounceLoader from "react-spinners/BounceLoader";
import { historicAlertData } from "./../../services/historicDataService.js";
import { defaultAlertSearchValue } from "./../../services/common.js";
import { useSelector, useDispatch } from "react-redux";

const override = css`
  display: block;
  margin: 0 auto;
`;

//function to fectch data from server
const getHistoricData = (props) => {
  const skillKey = props.skillKey ? props.skillKey : { name: "", type: "" };
  let alertData = [];
  let loading = true;

  const populateVendorsHistoricData = (alert) => {
    loading = true;
    if (!alert) {
      loading = false;
    }

    return historicAlertData(alert)
      .then(function (response) {
        alertData = response;
        loading = false;
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  if (skillKey.name) {
    return populateVendorsHistoricData(skillKey, "all")
      .then(() => {
        return { alertData, loading };
      })
      .catch((error) => {
        // handle error
        console.error(error);
        return { alertData, loading };
      });
  } else {
    loading = false;
    return { alertData, loading };
  }
};


function AlertTable(props) {
  const dispatch = useDispatch();
  const { alertSearchValue, alertTableData } = useSelector((s) => s);
  const params = { ...props, skillKey: { name: alertSearchValue } };
  const [filteredData, setFilteredData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(props.initialLoading);

  const flterAlertsData = (value) => {
    const lowerCaseValue = value.toLowerCase();
    const filteredAlerts = alertTableData.filter((row) => {
      if (
        row.company_ticker.toLowerCase().includes(lowerCaseValue) ||
        row.alert_text.toLowerCase().includes(lowerCaseValue) ||
        row.posted_date.toLowerCase().includes(lowerCaseValue)
      ) {
        return row;
      }
    });
    setFilteredData(filteredAlerts);
  };

  let columns = [
    {
      placeholder: "Alert Data",
      dataField: "company_ticker",
      classes: "id-custom-cell2",
      formatter: (value, row) => {
        return (
          <div className="list-f-inner">
            <div className="tech">{row.company_ticker}</div>
            <div className="tech_">
              <h6>{row.alert_text}</h6>
              <div className="small-t-y">{row.posted_date}</div>
            </div>
          </div>
        );
      },
    },
  ];

  //function to fetch Job Historic Data
  const getJobHistricData = (alertSearchValue) => {
    setLoading(true);
    params.skillKey = { name: alertSearchValue };

    getHistoricData(params)
      .then(({ alertData, loading }) => {
        dispatch({ type: "ALERT_TABLE_DATA", alertTableData: alertData });

        setTableData(alertData);
        setLoading(loading);
      })
      .catch((error) => {
        // Handle any errors here
        console.error(error);
      });
  };

  
  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = "/";
    }
    if (alertSearchValue) {
      getJobHistricData(alertSearchValue);
    }
    setInitialLoading(false);
  }, [alertSearchValue]);

  const dispatchAlertSearchValue = () => {
    dispatch({
      type: "ALERT_SEARCH_VALUE",
      alertSearchValue: defaultAlertSearchValue,
    });
  };

  useEffect(() => {
    if (!alertTableData.length) {
      dispatchAlertSearchValue();
    }
  }, []);

  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    sizePerPage: 5,
  });
  const rowStyle = (row, rowIndex) => {
    return { border: "" };
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Card className="box">
        <Card.Title>
          <h2
            className="heading"
            style={{ marginBottom: "-0.5%", marginLeft: "8px" }}
          >
            Alerts
          </h2>
        </Card.Title>
        {isLoading && (
          <BounceLoader color={"#36D7B7"} css={override} size={50} />
        )}
        {!isLoading && (
          <div>
            <input
              type="text"
              className="filterinput"
              placeholder="Filter Alert Data"
              onChange={(e) => flterAlertsData(e.target.value)}
            />
            <BootstrapTable
              key="alertTable"
              id="alertTable"
              name="alertTable"
              keyField="id"
              data={filteredData === null ? alertTableData : filteredData }
              columns={columns}
              pagination={pagination}
              sort={{ dataField: "date", order: "desc" }}
              rowClasses="alert-table-data"
              // filter={filterFactory()}
              rowStyle={rowStyle}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default AlertTable;

import axios from "axios";

const installsAndDownloadDataList = () => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
      method: "get",
      url: process.env.REACT_APP_SERVER_API + `api/visual-studio-list`,
      headers: {
        Authorization: `Token ${accessToken}`,
        "Content-Type": "application/json", // Set content type if required by your API
      },
      responseType: "json",
    });
  };

const historicInstallsAndDownloadData = (installsKey) => {
  const encoded_url = encodeURI(installsKey).replace("&", "%26");
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url:
      process.env.REACT_APP_SERVER_API + `api/visual-studio?name=${encoded_url}`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

  export { installsAndDownloadDataList, historicInstallsAndDownloadData };
import axios from "axios";

const historicIndexData = (index_id) => {
  const encoded_url = encodeURI(index_id);
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url:
      process.env.REACT_APP_SERVER_API + `api/index-data?id=${encoded_url}`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};
const indexDataList = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + `api/index-list`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

export { historicIndexData, indexDataList };

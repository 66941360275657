import React, { useEffect, useState } from "react";
import Navbar from './../Navbar/Navbar';
import SkillsTable from '../HistoricTable/SkillsTable';
import { Row, Col } from 'react-bootstrap';
import './Dashboard.css';
import { ReactComponent as LogoutSvg } from './../../assets/images/svgs/logout.svg';
import { useDispatch } from 'react-redux';
import Skills from './../Skills/Skills';
import { userLogout } from '../../services/authService';

function Dashboard(props) {
    const [isShow, setIsShow] = useState(false)
    const dispatch = useDispatch();
    const handleLogout = () => {
        setIsShow(true)
        userLogout();
    };

    useEffect(() => {
        dispatch({ type: 'SEARCH_KEY', searchkeyType: 'skills' });
    }, []);

    return (
        <div className="h-100">
            <Navbar />
            <Row className="aap-main-content">
                <Col lg={12}>
                    <Skills initialLoading={true} />
                    <SkillsTable
                        id="skillsTable"
                        name="skillsTable"
                        key="skillsTable"
                        initialLoading={true}
                    />
                </Col>
            </Row>
            <button id="logout" className="logout-btn" onClick={() => handleLogout()}>
                {(isShow ? <div style={{height: '30px', width: '30px'}}><span className="spinner-border" role="status" aria-hidden="true" style={{
                    marginTop: '5px',
                    width: '1.2rem',
                    height: '1.2rem'
                }}></span></div> : <LogoutSvg />)
                }
            </button >
        </div>
    );
}

export default Dashboard;

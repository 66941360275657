import React from 'react';

const Expired = () => {
    const goToLogin = () => {
        window.localStorage.removeItem('accessToken')
        window.localStorage.removeItem('reload_count')
        window.localStorage.removeItem('revelera_user')
        window.location.href = '/'
    }
    return (
        <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <h2>Your Trial or Api Key is Expired</h2>
                        <div className="mb-4 lead">Please contact sid@revealera.com for Access.</div>
                        <div className="error-actions align-center">
                            <button onClick={() => goToLogin()}
                                id="login"
                                className="btn btn-primary btn-lg">
                                <span className="glyphicon glyphicon-home"></span>
                                login </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expired;

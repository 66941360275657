import axios from "axios";

const upworkDataList = () => {
    const accessToken = window.localStorage.getItem("accessToken");
    return axios({
      method: "get",
      url: process.env.REACT_APP_SERVER_API + `api/upwork-list`,
      headers: {
        Authorization: `Token ${accessToken}`,
        "Content-Type": "application/json", // Set content type if required by your API
      },
      responseType: "json",
    });
  };

const historicUpworkData = (upwork) => {
  const encoded_url = encodeURI(upwork).replace("&", "%26");
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url:
      process.env.REACT_APP_SERVER_API + `api/upwork?skill=${encoded_url}`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

  export { upworkDataList, historicUpworkData };
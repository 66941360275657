import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

function IndustryChart(props) {
  const totalJobOpenings = [];
  const newJobOpenings = [];
  const industryLabels = [];
  props.data?.map((item) => {
    totalJobOpenings.push(item.total_job_openings);
    newJobOpenings.push(item.new_job_openings);
    industryLabels.push(item.change_date)
  });
  const [industryChartOptions, setIndustryChartOptions] = useState({
    legend: {
      show: true,
      textStyle: {
        color: "white",
      },
    },
    xAxis: {
      type: "category",
      data: industryLabels,
      axisLabel: {
        color: "#77778e",  
        rotate: 28, 
        // interval: 60,
        fontSize: 11,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
          pixelRatio: 2,
          backgroundColor: '#32323e',
        },
      },
    },
    animation: false,
    yAxis: [
      {
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(80, 80, 80, 0.8)",
            width: 1,
          },
        },
      },
      {
        splitLine: {
          show: false,
        },
      },],
    series: [
      {
        name: "# of total job openings",
        data: totalJobOpenings,
        type: "line",
        yAxisIndex: 0,
        lineStyle: {
          color: 'rgb(22, 202, 242)',
        },
        symbol: 'none',
        color: 'rgb(22, 202, 242)',
      },
    ],
  });
  useEffect(() => {
    if(props.isChecked == "source"){
      setIndustryChartOptions({...industryChartOptions, xAxis: {
        type: "category",
        data: industryLabels,
        axisLabel: {
          color: "#77778e",  
          rotate: 25, 
          // interval: 60,
          fontSize: 11,
        },
      },
        series: [
          {
            name: "num_new_jobs_past_x_days",
            data: newJobOpenings,
            type: "line",
            yAxisIndex: 0,
            lineStyle: {
              color: 'rgb(22, 202, 242)',
            },
            symbol: 'none',
            color: 'rgb(22, 202, 242)',
          },
        ],
      });
    }else{
      setIndustryChartOptions({...industryChartOptions, xAxis: {
        type: "category",
        data: industryLabels,
        axisLabel: {
          color: "#77778e",  
          rotate: 25, 
          // interval: 60,
          fontSize: 11,
        },
      },
        series: [
          {
            name: "# of total job openings",
            data: totalJobOpenings,
            type: "line",
            yAxisIndex: 0,
            lineStyle: {
              color: 'rgb(22, 202, 242)',
            },
            symbol: 'none',
            color: 'rgb(22, 202, 242)',
          },
          {
            name: "# of new job openings",
            data: newJobOpenings,
            type: "line",
            yAxisIndex: 1,
            lineStyle: {
              color: 'rgb(255, 255, 0)',
            },
            symbol: 'none',
            color: 'rgb(255, 255, 0)',
          },
        ],
      });
    }
  }, [props]);
  return (
      <ReactEcharts option={industryChartOptions} style={{ height: '400%', width: '100%', }} />
  );
}

export default IndustryChart;

import axios from "axios";
import { reloadIfTokenExpire } from "./common";

export const historicJobsData = (ticker, jobType) => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url:
      process.env.REACT_APP_SERVER_API +
      `api/jobs-opening-trends.csv?ticker=${ticker}&job_type=${jobType}`,
    headers: {
      Authorization: `TokenaccessToken ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};
export const historicSkillsData = (technology) => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url:
      process.env.REACT_APP_SERVER_API +
      `api/skills-opening-trend?technology=${technology}`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

export const historicVendorsData = (vendor) => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + `api/vendors?vendor=${vendor.name}`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },

    responseType: "json",
  });
};
 
export const historicAlertData = (alert) => {
  const accessToken = window.localStorage.getItem("accessToken");

  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + "api/alerts/?category=ticker",
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      // handle error
      reloadIfTokenExpire(error.response.data);
      console.error(error);
      throw error; // Rethrow the error to propagate it further if needed
    });
};


export const createCsv = (csvFileDataObj, props) => {
  if (!csvFileDataObj.length) return false;
  let csvFileData = [];
  let csv = "";
  if (props.skillKey.type == "jobs") {
    csvFileData = csvFileDataObj.map((e) => [
      e.date,
      e.total_job_openings,
      e.num_new_jobs_past_84_days,
    ]);
    csv = "Date, TOTAL JOB OPENINGS, NUM OF NEW JOBS PAST 84 DAYS \n";
  } else if (props.skillKey.type == "saas") {
    csvFileData = csvFileDataObj.map((e) => [
      e.change_date,
      e.current_status,
      e.org_alias,
      e.prev_status,
    ]);
    csv = "Change Date, Current Status, Org Alias, Prev Status \n";
  } else if (props.skillKey.type == "alert") {
    csvFileData = csvFileDataObj.map((e) => [
      e.alert_key,
      e.alert_text,
      e.company_ticker,
    ]);
    csv = "Change Date, Current Status, Org Alias, Prev Status \n";
  } else if (props.skillKey.type == "industry") {
    csvFileData = csvFileDataObj.map((e) => [
      e.change_date,
      e.total_job_openings,
      e.new_job_openings,
    ]);
    csv = "SNAPSHOT DATE, TOTAL JOB OPENINGS, NEW JOB OPENINGS  \n";
  } else if (props.skillKey.type == "upwork") {
    csvFileData = csvFileDataObj.map((e) => [
      e.snapshot_date,
      e.trailing_84_day_num_jobs_sum,
      e.num_new_jobs,
    ]);
    csv = "SNAPSHOT DATE, NEW JOB OPENINGS 84 DAY TRAILING SUM, NUMBER OF NEW JOB  \n";
  } else if (props.skillKey.type == "install-and-downloads") {
    csvFileData = csvFileDataObj.map((e) => [
      e.snapshot_date,
      e.moving_84_day_avg_num_installs,
      e.num_new_installs,
    ]);
    csv = "SNAPSHOT DATE, NUMBER OF INSTALLS (84 DAY MOVING AVERAGE), NUMBER OF NEW JOB  \n";
  } else {
    csvFileData = csvFileDataObj.map((e) => [
      e.date,
      e["% of companies (84 day moving average)"],
      e["% of job openings (84 day moving average)"],
      e["number of companies"],
      e["number of job openings"],
      e["total companies"],
      e["total job openings"],
    ]);
    csv =
      "Date,% of companies,% of job openings,Companies,Job Openings,Total companies,Total job openings\n";
  }

  csvFileData.forEach(function (row) {
    csv += row.join(",");
    csv += "\n";
  });
  let hiddenElement = document.createElement("a");
  hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(csv);
  hiddenElement.target = "_blank";
  let fileName = props.skillKey.name ? props.skillKey.name + " " : "";
  if (fileName) {
    fileName = fileName[0].toUpperCase() + fileName.slice(1);
  }
  fileName += "Historical Data.csv";
  hiddenElement.download = fileName;
  hiddenElement.click();
};

import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Card, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./HistoricTable.css";
import BounceLoader from "react-spinners/BounceLoader";
import {
  historicVendorsData,
  createCsv,
} from "./../../services/historicDataService.js";
import { checkExpiredPeriod, defaultSaasSearchValue, reloadIfTokenExpire } from "./../../services/common.js";
import { useSelector, useDispatch } from "react-redux";
import SaasSubsChart from "../saasSub/SaasSubsChart";

const override = css`
  display: block;
  margin: 0 auto;
`;

const getHistoricData = (props) => {
  const skillKey = props.skillKey ? props.skillKey : { name: "", type: "" };
  let saasData = [];
  let saasPositiveNagetiveChg = [];
  let loading = true;

  const populateVendorsHistoricData = (vendor) => {
    loading = true;
    if (!vendor) {
      loading = false;
      return Promise.resolve(); // Return a resolved promise to simulate the behavior of await
    }

    return historicVendorsData(vendor)
      .then(function (response) {
        saasData = response.data.changes ? response.data.changes : [];
        saasPositiveNagetiveChg = response.data.positive_negative_changes;
        loading = false;
      })
      .catch(function (error) {
        // handle error
        reloadIfTokenExpire(error.response.data);
        console.log(error);
      });
  };

  return skillKey.name
    ? populateVendorsHistoricData(skillKey, "all").then(() => {
        return { saasData, loading, saasPositiveNagetiveChg };
      })
    : Promise.resolve({ saasData, loading, saasPositiveNagetiveChg });
};



function SaasTable(props) {
  const dispatch = useDispatch();
  const { saasSearchValue, saasTableData, suggestionsList } = useSelector((s) => s);
  
  const params = { ...props, skillKey: { name: saasSearchValue } };

  const [tableData, setTableData] = useState([]);
  const [saasPnChg, setsaasPnChg] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(props.initialLoading);

  let columns = [
    {
      dataField: "change_date",
      text: "Change Date",
      sort: true,
      headerStyle: (col, colIdx) => {
        return { width: "200px" };
      },
    },
    { dataField: "current_status", text: "Current Status", sort: true },
    { dataField: "org_alias", text: "Org Alias", sort: true },
    { dataField: "prev_status", text: "Prev Status", sort: true },
  ];

  const handleDownload = (csvFileDataObj) => {
    csvFileDataObj.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    createCsv(csvFileDataObj, {
      skillKey: { name: saasSearchValue, type: "saas" },
    });
  };

  const getJobHistricData = (saasSearchValue) => {
    setLoading(true);
    params.skillKey = { name: saasSearchValue };
  
    getHistoricData(params)
      .then(({ saasData, loading, saasPositiveNagetiveChg }) => {
        setsaasPnChg(saasPositiveNagetiveChg);
        dispatch({ type: "SAAS_TABLE_DATA", saasTableData: saasData });
        setTableData(saasData);
        setLoading(loading);
      })
      .catch((error) => {
        console.error("Error getting historic data:", error);
      });
  };
  
  useEffect(() => {
    if (saasSearchValue) getJobHistricData(saasSearchValue);
    setInitialLoading(false);
  }, [saasSearchValue]);

  const dispatchSaasSearchValue = (dValue) => {
    dispatch({
      type: "SAAS_SEARCH_VALUE",
      saasSearchValue: dValue,
      // saasSearchValue: defaultSaasSearchValue,
    });
  };

  useEffect(() => {
    setLoading(true)
    if (suggestionsList.length) {
      for (let i = 0; i < suggestionsList.length; i++) {
        const element = suggestionsList[i];
        // Trial User
        if (element.type === "saas" && element.trialAccess === true) {
          dispatchSaasSearchValue(element.name);
          setLoading(false)
          break;
        }
        // Regular User
        if (element.type === "saas" && element.trialAccess === 0 && element.isTrial === false) {
          dispatchSaasSearchValue(defaultSaasSearchValue);
          setLoading(false)
          break;
        }
      }
    }
  }, [suggestionsList]);

  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    sizePerPage: 50,
  });

  return (
    <>
      <Card className="box">
        <Card.Body>
          <Card.Title>
            <div className="skill-data-header-wrapper">
              <h2 className="heading">
                {saasSearchValue && (
                  <div>
                    <span className="text-capitalize">{saasSearchValue}</span>
                  </div>
                )}
              </h2>
            </div>
          </Card.Title>
        </Card.Body>
        {isLoading ? (
          <BounceLoader color={"#36D7B7"} css={override} size={50} />
        ) : (
          <SaasSubsChart saasPnChg={saasPnChg} />
        )}
      </Card>
      <div style={{ marginTop: 30 }}>
        <Card className="box">
          {
            <h2 className="heading relative">
              {saasSearchValue && (
                <div>SaaS Subscriptions for {saasSearchValue}</div>
              )}
              <Button
                onClick={() => handleDownload(saasTableData)}
                className={`position-right custom-dark-mode ${
                  !tableData.length ? "disabled" : ""
                }`}
              >
                Download Data
              </Button>{" "}
            </h2>
          }
          {isLoading && (
            <BounceLoader color={"#36D7B7"} css={override} size={50} />
          )}
          {!isLoading && (
            <BootstrapTable
              key="saasTable"
              id="saasTable"
              name="saasTable"
              keyField="org_alias"
              data={saasTableData}
              columns={columns}
              pagination={pagination}
              sort={{ dataField: "date", order: "desc" }}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default SaasTable;

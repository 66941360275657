import {NavLink} from 'react-router-dom';
import logo from '../../assets/images/logo-white.png';
import Menu from '../../components/Menu/Menu'
import './Docs.css';

function Docs() {
    return (
        <div className="h-100">
            <div className="row header-nav">
                <div className="col-lg-12 nav-wrapper">
                    <div className="nav-left">
                        <div className="logo">
                            <NavLink to="/home">
                                <img src={logo}/>
                            </NavLink>
                        </div>
                        <Menu/>
                    </div>
                </div>
            </div>
            <div className="container docs-content">
                <h3>
                    <table>
                        <tbody>
                        <tr>
                            <td><a title="SaaS Signup Tracking Manual"
                                   href="/pdfs/Revealera-SaaS-Signup-Tracking-FAQ.pdf" download>
                                <img
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAC+UlEQVRogd2aP2gUQRTG35xGtBSSoLERQYgaECQgCFbaqEjAYCWCVnZRxMLCRhAUtBBEjPYaQRBSxUZQgtqIIQox4l8QRfyDiOIFk/iz2Ce37u3e7ezN21O/ZvZ2Zt73fft2Z9/unsh/AteoE8CId1JEtjrnPoYKWAkVyBPrReQm0FkKGwqLmIopYFnI+A1JLWICk7G2KyRHJqlFTGApcF+3H5tmxtKIbncBD83NWBvR30kzy0PypZJaxTQ3U5YR3W9npkwj2tcFPNIh08HMlG1E+7sTZnrMSa1iBjfTLiM6zstMrqLROddwnA9aODDTzrk1WZ3/kpGGOhYWDVoURQ5KHvPtKuODw8QIcAp4D0wAqy04fAV5r1rAJv7E1TJ0WGSkV9tn2vYZcNTBwsgrbVdqO2/AUQcLI/dEZEZqK+K0AUcdghtxzlVFZCS2ayw0hzeKlihAf+xiz7wbh9RhdR/pjm2fNOLIjxYyci6xBO9vh46WAgAV4I1OPQLMA1Vgi6UOi1prl4j0iMgT59wZ5T8tIqPAoESF6kYd811EJkRkxDk3mxWw5aLVNyOajd/P3XuJ3l3tBN7SGFdaEppDmK+RfTrlB/BAT6s8+JDFnQZTI0QvDt4lOL8Co8BBohqsHxhL0Xap7UaAVcAw8C3GNQ7sBhZlzOkDDgCHgW1A3bWaxm1iBFgAHAdmYwbmgR3eRDm5rYycSMn65YK6c3FbGfmcMPGcgB9ufI20UqLcim1PSeBPab4o/BYF6BCRAYlK9hvOubmQwtK4G+lpGqzQORkAZZ5awQCcTa4aTcYncb4ZQSkZISptrqcITM1IArfJuF/VTTR1UeNaAtxVyqNNxg7puBfkWSnLvkaATuCp0h7KGLMdmAO+AOvyBi7ViHL2Ap+AnyQeyIANRPXbHD4VRDuMKO9mYEYFD+q+FcBrlTTkG7BM3AEWx7j3EGWlCgxQe865WOTIlI1rQCXGfyzRP06zFepvBXBBTbzE+q8elgA6NFNr262lFPwCMllglngDQUwAAAAASUVORK5CYII="/>
                                SaaS Signup Tracking Manual
                            </a></td>
                        </tr>
                        <tr>
                            <td>
                                <a title="Revealera API Documentation.pdf" href="/pdfs/Revealera-API-Documentation.pdf"
                                   download>
                                    <img
                                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAC+UlEQVRogd2aP2gUQRTG35xGtBSSoLERQYgaECQgCFbaqEjAYCWCVnZRxMLCRhAUtBBEjPYaQRBSxUZQgtqIIQox4l8QRfyDiOIFk/iz2Ce37u3e7ezN21O/ZvZ2Zt73fft2Z9/unsh/AteoE8CId1JEtjrnPoYKWAkVyBPrReQm0FkKGwqLmIopYFnI+A1JLWICk7G2KyRHJqlFTGApcF+3H5tmxtKIbncBD83NWBvR30kzy0PypZJaxTQ3U5YR3W9npkwj2tcFPNIh08HMlG1E+7sTZnrMSa1iBjfTLiM6zstMrqLROddwnA9aODDTzrk1WZ3/kpGGOhYWDVoURQ5KHvPtKuODw8QIcAp4D0wAqy04fAV5r1rAJv7E1TJ0WGSkV9tn2vYZcNTBwsgrbVdqO2/AUQcLI/dEZEZqK+K0AUcdghtxzlVFZCS2ayw0hzeKlihAf+xiz7wbh9RhdR/pjm2fNOLIjxYyci6xBO9vh46WAgAV4I1OPQLMA1Vgi6UOi1prl4j0iMgT59wZ5T8tIqPAoESF6kYd811EJkRkxDk3mxWw5aLVNyOajd/P3XuJ3l3tBN7SGFdaEppDmK+RfTrlB/BAT6s8+JDFnQZTI0QvDt4lOL8Co8BBohqsHxhL0Xap7UaAVcAw8C3GNQ7sBhZlzOkDDgCHgW1A3bWaxm1iBFgAHAdmYwbmgR3eRDm5rYycSMn65YK6c3FbGfmcMPGcgB9ufI20UqLcim1PSeBPab4o/BYF6BCRAYlK9hvOubmQwtK4G+lpGqzQORkAZZ5awQCcTa4aTcYncb4ZQSkZISptrqcITM1IArfJuF/VTTR1UeNaAtxVyqNNxg7puBfkWSnLvkaATuCp0h7KGLMdmAO+AOvyBi7ViHL2Ap+AnyQeyIANRPXbHD4VRDuMKO9mYEYFD+q+FcBrlTTkG7BM3AEWx7j3EGWlCgxQe865WOTIlI1rQCXGfyzRP06zFepvBXBBTbzE+q8elgA6NFNr262lFPwCMllglngDQUwAAAAASUVORK5CYII="/>
                                    Revealera API Documentation
                                </a></td>
                        </tr>
                        </tbody>
                    </table>
                </h3>
            </div>
        </div>
    );
}

export default Docs;
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import Menu from "../../components/Menu/Menu";
import "./Navbar.css";
import {
  getCompanySkillSuggestion,
  addWatchlist,
} from "./../../services/navbarService.js";
import {
  checkExpiredPeriod,
  reloadIfTokenExpire,
} from "./../../services/common.js";
import logoImg from "../../assets/images/logo-white.png";
import { useSelector, useDispatch } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Navbar(props) {
  const dispatch = useDispatch();
  const { searchkeyType, searchkeyValue, suggestionsList, jobsFilterParams } =
    useSelector((state) => state);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength === 0
      ? []
      : suggestionsList.filter((lang) => {
          return (
            lang.type == searchkeyType &&
            (String(lang.name).toLowerCase().startsWith(inputValue) ||
              String(lang.ticker).toLowerCase().startsWith(inputValue))
          );
        });
  };

  const user = window.localStorage.getItem("revelera_user");
  const userParse = JSON.parse(user);
  const userPicture = userParse && userParse.picture ? userParse.picture : "";

  const [value, setValue] = useState("");
  const [getSuggestionStatus, setSuggestionEnable] = useState(true);
  const [suggestions, setSuggestions] = useState([]);

  const handleAddToWatchlist = (e, suggestion) => {
    e.stopPropagation();
    e.target.style.display = "none";
    setValue("");
    setSuggestions([]);
    addWatchlist(suggestion)
      .then(function (response) {
        props.watchListAdded(suggestion);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  const getSuggestionValue = (suggestion) => {
    return suggestion.name;
  };

  const renderSuggestion = (suggestion) => (
    <div className="suggestion-wrap">
      <div
        className={`suggestion-row ${
          suggestion?.isTrial && !suggestion?.trialAccess && "trial-suggestion"
        }`}
        title={`${
          searchkeyType == "skills" &&
          suggestion?.isTrial &&
          !suggestion?.trialAccess
            ? "This Product Mention is only available to Subscribers. Please contact sid@revealera.com for access."
            : "" ||
              (searchkeyType == "jobs" &&
                suggestion?.isTrial &&
                !suggestion?.trialAccess)
            ? "This Job Trend is only available to Subscribers. Please contact sid@revealera.com for access."
            : "" ||
              (searchkeyType == "saas" &&
                suggestion?.isTrial &&
                !suggestion?.trialAccess)
            ? "This Saas Subscription is only available to Subscribers. Please contact sid@revealera.com for access."
            : ""
        }`}
      >
        <div className="flex1">
          <b className="text-capitalize">
            {suggestion.name}{" "}
            {suggestion.ticker ? "( " + suggestion.ticker + " )" : ""}{" "}
          </b>{" "}
        </div>
        {suggestion.type == "jobs" &&
          (!suggestion?.isTrial || suggestion?.trialAccess) && (
            <div className="suggst-bookmark" title="Add to Watchlist">
              <svg
                onClick={(e) => handleAddToWatchlist(e, suggestion)}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-bookmark"
                viewBox="0 0 16 16"
              >
                <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
              </svg>
            </div>
          )}
      </div>
    </div>
  );

  const enableAutoSuggest = () => {
    setSuggestionEnable(false);
  };

  useEffect(() => {
    if (!suggestionsList.length) {
      const searchList = [];
      if (searchList.length) {
        setValue("");
        enableAutoSuggest();
        return false;
      }
      let dotCount = 0;
      const interval1 = setInterval(() => {
        dotCount++;
        setValue(".".repeat((dotCount % 3) + 1));
      }, 500);
      getCompanySkillSuggestion()
        .then(function (response) {
          checkExpiredPeriod(response?.data?.STATUS, response?.data?.message);
          reloadIfTokenExpire(response.data);
          clearInterval(interval1);
          setValue("");
          const isTrial = userParse.user_status == "TRIAL" ? true : false;
          let trialTickers = [];
          let trialSkills = [];
          let trialVendors = [];
          let trailAlerts = [];
          if (response?.data?.trial_data) {
            trialTickers = response?.data?.trial_data?.ticker || [];
            trialTickers = trialTickers.map((e) => e.toLowerCase());
            trialSkills = response?.data?.trial_data?.skill || [];
            trialSkills = trialSkills.map((e) => e.toLowerCase());
            trialVendors = response?.data?.trial_data?.vendor || [];
            trialVendors = trialVendors.map((e) => e.toLowerCase());
            trailAlerts = response?.data?.trial_data?.alerts || [];
            trailAlerts = trailAlerts.map((e) => e.toLowerCase());
          }

          const job_keys = Object.keys(response.data["jobs"]);
          job_keys.map((job_key) => {
            const jd = response.data["jobs"][job_key];
            const ticker =
              typeof jd.ticker === "string"
                ? jd.ticker.toLowerCase()
                : jd.ticker;
            searchList.push({
              name: job_key,
              type: "jobs",
              ticker: jd.ticker,
              isTrial,
              trialAccess:
                trialTickers.length &&
                (ticker || job_key) &&
                (trialTickers.includes(ticker) ||
                  trialTickers.includes(job_key)),
            });
          });
          response.data["skills"].map((name) => {
            searchList.push({
              name,
              type: "skills",
              isTrial,
              trialAccess:
                trialSkills.length &&
                name &&
                trialSkills.includes(name?.toLowerCase()),
            });
          });
          response.data["vendors"].map((name) => {
            searchList.push({
              name,
              type: "saas",
              isTrial,
              trialAccess:
                trialVendors.length &&
                name &&
                trialVendors.includes(name?.toLowerCase()),
            });
          });
          // response.data['alerts'].map((name) => {
          //     console.log('----');
          //     searchList.push({
          //         name,
          //         type: 'alerts',
          //         isTrial,
          //         trialAccess: trailAlerts.length && name && trialTickers.includes(name?.toLowerCase())
          //     });
          // });
          enableAutoSuggest();

          dispatch({
            type: "SUGGESTIONS_LIST",
            suggestionsList: searchList,
          });
        })
        .catch(function (error) {
          reloadIfTokenExpire(error.response.data);
          clearInterval(interval1);
          setValue("");
        })
        .finally(function () {
          // always executed
        });
    } else {
      enableAutoSuggest();
    }
  }, []);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionSelected = (e, v) => {
    if (!v.suggestion?.isTrial || v?.suggestion?.trialAccess) {
      if (searchkeyType == "jobs")
        dispatch({
          type: "JOBS_FILTER_PARAMS",
          jobsFilterParams: {
            ...jobsFilterParams,
            ticker: v.suggestion.ticker,
            companyName: v.suggestion.name,
            filter: "all",
            showChild: "false",
          },
        });
      if (searchkeyType == "skills")
        dispatch({
          type: "SKILL_SEARCH_VALUE",
          skillSearchValue: v.suggestion.name,
        });
      if (searchkeyType == "saas")
        dispatch({
          type: "SAAS_SEARCH_VALUE",
          saasSearchValue: v.suggestion.name,
        });
      if (searchkeyType == "alerts")
        dispatch({
          type: "ALERT_SEARCH_VALUE",
          alertSearchValue: v.suggestion.name,
        });
    } else {
      setValue("");
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Find Jobs, Skills",
    value: value,
    onChange: onChange,
    className: "input-control line-height-32",
    disabled: getSuggestionStatus,
  };

  const logo = (
    <div className="logo">
      <NavLink to="/home">
        <img src={logoImg} />
      </NavLink>
    </div>
  );
  const searchBar = (
    <div className="SearchBarContainer">
      <div className="flex-grow-input">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
        />
      </div>
    </div>
  );
  const userIcon = userPicture ? (
    <div className="userIcon">
      <img src={userPicture} style={{ width: "100%", borderRadius: "25px" }} />
    </div>
  ) : (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id="button-tooltip-2">
          {userParse ? userParse.email : "Avatar is'nt available"}
        </Tooltip>
      }
    >
      <div className="userIcon without-image">
        {userParse ? userParse.email.substring(0, 2).toUpperCase() : ""}
      </div>
    </OverlayTrigger>
  );

  
  return (
    <div className="row header-nav">
      <div className="col-lg-12">
        <div className="nav-wrapper">
          <div className="nav-left">
            {/* <Hamburger size={20} /> */}
            <div className="logo">{logo}</div>
            <Menu />
          </div>
          <div className="nav-right">
          {searchBar}
          {userIcon}
          </div>
        </div>
      </div>
      {/* <div className="col-lg-12 d-block d-sm-none">
        <div className="nav-wrapper">
          <div className="nav-left">
            {logo}
            <Menu />
          </div>
          <div className="nav-right">
            {searchBar}
            {userIcon}
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Navbar;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { resetPassword, sentOtp } from "../../../services/authService";
import axios from "axios";
import "../Auth.css";
import { series } from "../../../services/skillDataService";
import { responsivePropType } from "react-bootstrap/esm/createUtilityClasses";
import { RotatingLines } from "react-loader-spinner";
import 'bootstrap-icons/font/bootstrap-icons.css';
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");
  const [error, setError] = useState("");
  const [isShow, setIsShow] = useState(false);
  const [isloader, setIsloader] = useState(false);

  // Function for sending and resending OTP on email
  const handleSendOtp = () => {
    setIsloader(true);
    if (email === "") {
      toast("Email is required");
      setError("Email is required");
    } else {
         sentOtp(email)
        .then(function (response) {
          if (response.data.status === "KO") {
            toast("This Email is not valid");
          }
          if (response.status === 200) {
            setIsloader(false);
            if (response.data.status === "KO") {
              isShow == false;
            } else {
              if (isShow) {
                toast("Resend OTP");
              } else {
                toast("OTP Send Successfully");
              }
              setIsShow(true);
            }
          }
        })
        .catch(function (error) {

        });
    }
  };

  //Function for changing password
  const handleChangePassword = (e) => {
    if (password !== conformPassword) {
      toast("Password didn't match");
      setError("Password didn't match");
    } else {
       resetPassword({
        email,
        otp,
        password,
      })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.status === "KO") {
              toast("OTP is not correct");
            } else {
              if (response.data.status === 'OK') {
                navigate("/login");
              }
            }
          }
        })
        .catch(function (error) {
          toast(error.response.data.message);
          setError(error.response.data.message);
        });
    }
  };

  return (
    <div className="App">
      <div className="auth-wrapper">
        <form className="auth-inner">
        {!isShow ? (
          <div style={{ transform: 'translate(-35px, -25px)', cursor: 'pointer' }} className="">
            <i className="bi bi-arrow-left-circle-fill fs-6 " onClick={() => {
              navigate("/login");
            }}></i>
          </div>
        ) : ''}
          <h3 style={{ marginBottom: "10px", textAlign: "center" }}>
            Forget Password
          </h3>
          <div className="form-group">
            <label htmlFor="emailId">Enter Email</label>
            <div className="form-input-group">
              {isShow ? (
                <div className="input-group">
                  <div style={{ position: "relative" }}>
                    <input
                      style={{ backgroundColor: "#2d2d48", paddingRight: "49px" }}
                      value={email}
                      className="form-control email-input"
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Enter Email"
                      name="emailId"
                      title="Add New Email"
                      disabled
                    />
                    <label
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        bottom: 0,
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "10px",
                        cursor: "pointer",
                      }}
                      htmlFor="emailId"
                      title="Add New Email"
                      onClick={() => {
                        setIsShow(false)
                      }}
                    >
                      📩
                    </label>
                  </div>
                  <div className="input-group-append">
                    <button
                      style={{ background: "#52e3c2", color: "rgb(0 0 0)" }}
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => handleSendOtp()}
                    >
                      Resend
                    </button>
                  </div>
                </div>
              ) : (
                <div className="input-group">
                  <input
                    style={{ backgroundColor: "#2d2d48 !important" }}
                    value={email}
                    className="form-control email-input"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Enter Email"
                    name="emailId"
                  />
                  <div className="input-group-append">
                    <button
                      style={{ background: "#52e3c2", color: "rgb(0 0 0)" }}
                      className="btn btn-outline-secondary"
                      type="button"
                      onClick={() => handleSendOtp()}
                    >
                      Send OTP
                    </button>
                  </div>
                </div>
              )}
            </div>
            {isloader && (
              <div className="pt-2 text-center d-flow-root">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="36"
                  visible={true}
                />
              </div>
            )}
            {isShow && (
              <div style={{ marginTop: "15px" }}>
                <div className="form-group">
                  <label>Enter OTP</label>
                  <input
                    className="form-control"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    type="text"
                    placeholder="Enter OTP"
                  />
                </div>
                <div style={{ marginTop: "8px" }} className="form-group">
                  <label>Enter Password</label>
                  <input
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="text"
                    placeholder="Enter Password"
                  />
                </div>
                <div style={{ marginTop: "8px" }} className="form-group">
                  <label>Confirm Password</label>
                  <input
                    className="form-control"
                    value={conformPassword}
                    onChange={(e) => setConformPassword(e.target.value)}
                    type="password"
                    placeholder="Confirm Password"
                  />
                </div>

                <button
                  className="btn btn-primary btn-block form-control mt-3 border-radius-25"
                  type="button"
                  onClick={() => handleChangePassword()}
                >
                  Change Password
                </button>
              </div>
            )}
          </div>
          {/* {error.length > 0 && <p className="error-message">{error}</p>} */}
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;

import axios from "axios";

export const getWatchlist = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + "api/watchlist/jobs/",
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

export const removeWatchlist = (el) => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "DELETE",
    url:
      process.env.REACT_APP_SERVER_API +
      "api/watchlist/jobs/?company_name=" +
      el.company_name,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },

    responseType: "json",
  });
};

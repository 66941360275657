import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Card, Button } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./HistoricTable.css";
import BounceLoader from "react-spinners/BounceLoader";
import {
  historicIndexData,
  indexDataList,
} from "../../services/indicesService";
import IndustryChart from "../Industry/IndustryChart";
import { useDispatch } from "react-redux";
import { createCsv } from "./../../services/historicDataService";
import { reloadIfTokenExpire } from "../../services/common";
import "./IndicesTable.css";

const override = css`
  display: block;
  margin: 0 auto;
`;

function IndicesTable() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [finalData, setFinalData] = useState([]);
  const [indexList, setIndexList] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [indexName, setIndexName] = useState(indexList[1]);

  useEffect(() => {
    if (!isFirstLoad) {
      const newData = getIndexData(indexList[0]);
    } else {
      setIsFirstLoad(false);
    }
  }, []);

  let columns = [
    {
      dataField: "change_date",
      text: "Date",
      sort: true,
      headerStyle: (col, colIdx) => {
        return { width: "200px" };
      },
    },
    { dataField: "total_job_openings", text: "Total job openings", sort: true },
    { dataField: "new_job_openings", text: "New job openings", sort: true },
  ];

  const getIndexData = (index_id) => {
    setIndexName(index_id);
    setLoading(true);
    historicIndexData(index_id)
      .then((res) => {
        reloadIfTokenExpire(res.data);
        if (res.data !== "No Response") {
          const data = res.data;
          const indexDataObj = [];
          const data_keys = Object.keys(data);
          data_keys.map((date, index) => {
            const row = data[date];
            indexDataObj.push({
              key: index,
              change_date: date,
              total_job_openings: row["total_job_openings"],
              new_job_openings: row["new_job_openings"],
            });
          });
          const revercedIndexDataObj = indexDataObj.reverse();
          dispatch({
            type: "INDEX_TABLE_DATA",
            indexTableData: revercedIndexDataObj,
          });
          setFinalData(revercedIndexDataObj);
          setLoading(false);
        } else {
          console.log("Index Not Found");
        }
      })
      .catch((err) => {
        reloadIfTokenExpire(err.response.data);
        console.log("Index ID Not Found");
      });
  };

  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = "/";
    }
    let isMounted = true; // Flag to track component's mount state

    const fetchMacrosData = () => {
      setLoading(true);
      indexDataList()
        .then((data) => {
          reloadIfTokenExpire(data.data);
          getIndexData(data.data.indexList[0].name);
          if (isMounted) {
            // Check if component is still mounted before updating state
            setIndexList(data.data.indexList);
          }
        })
        .catch((error) => {
          reloadIfTokenExpire(error.response.data);
          console.error("Error fetching index data:", error);
        });
    };

    // Call the fetch functions and set up the cleanup logic
    Promise.all([fetchMacrosData()]).then((e) => {
      if (isMounted) {
        console.log("it1:promise all: isMounted");
      }
    });

    // Cleanup function to handle component unmount
    return () => {
      isMounted = false; // Update the flag to indicate that component is unmounted
    };
  }, []);

  const handleDownload = (csvFileDataObj) => {
    csvFileDataObj.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    createCsv(csvFileDataObj, {
      skillKey: { name: "industryName", type: "industry" },
    });
  };

  const handleSelectChange = (eventKey) => {
    setIndexName(eventKey);
    getIndexData(eventKey);
  };

  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    sizePerPage: 50,
  });

  return (
    <>
      <Card className="box">
        <Card.Body>
          <Card.Title>
            <div className="skill-data-header-wrapper">
              <h2 className="heading">
                {true && (
                  <div>
                    <div>
                      <span className="text-capitalize">{indexName}</span>
                    </div>
                  </div>
                )}
              </h2>
              <div className="skill-data-header">
                <Dropdown className="dropdown-select mr-2" onSelect={(eventKey) => handleSelectChange(eventKey)}>
                  <Dropdown.Toggle
                    id="indexdropdown"
                    className="dropdown-select"
                  >
                    {indexName?.charAt(0).toUpperCase() + indexName?.slice(1)}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {indexList
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((e) => (
                      <Dropdown.Item key={e.id} eventKey={e.name}>
                        {e.name?.charAt(0).toUpperCase() + e.name?.slice(1)}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Card.Title>
        </Card.Body>
        {isLoading ? (
          <BounceLoader color={"#36D7B7"} css={override} size={50} />
        ) : (
          <IndustryChart data={finalData} />
        )}
      </Card>
      <div style={{ marginTop: 30 }}>
        <Card className="box">
          {
            <h2 className="heading relative">
              {true && <div>Data for {indexName}</div>}
              <Button
                onClick={() => handleDownload(finalData)}
                className={`position-right custom-dark-mode ${
                  !finalData.length ? "disabled" : ""
                }`}
              >
                Download Data
              </Button>{" "}
            </h2>
          }
          {isLoading && (
            <BounceLoader color={"#36D7B7"} css={override} size={50} />
          )}
          {!isLoading && (
            <BootstrapTable
              key="saasTable"
              id="saasTable"
              name="saasTable"
              keyField="date"
              data={finalData}
              columns={columns}
              pagination={pagination}
              sort={{ dataField: "change_date", order: "desc" }}
            />
          )}
        </Card>
      </div>
    </>
  );
}

export default IndicesTable;

import React from "react";
import { useState } from "react";
import { userLogout } from "../../services/authService";
import IndustryTable from "../HistoricTable/IndustryTable";
import Menu from "../../components/Menu/Menu";
import "./Dashboard.css";
import { ReactComponent as LogoutSvg } from "./../../assets/images/svgs/logout.svg";
import { NavLink } from "react-router-dom";
import logo from '../../assets/images/logo-white.png'

function IndustryDashboard() {
  const [isShow, setIsShow] = useState(false)
    const handleLogout = () => {
        setIsShow(true)
        userLogout();
    };
  return (

    <div className="h-100">
      <div className="row header-nav">
        <div className="col-lg-12 nav-wrapper">
          <div className="nav-left">
            <div className="logo">
              <NavLink to="/home">
                <img src={logo} />
              </NavLink>
            </div>
            <Menu />
          </div>
        </div>
      </div>
      <IndustryTable
        id="industryTable"
        name="industryTable"
        key="industryTable"
        initialLoading={true}
      />
      <button id="logout" className="logout-btn" onClick={() => handleLogout()}>
                {(isShow ? <div style={{height: '30px', width: '30px'}}><span class="spinner-border" role="status" aria-hidden="true" style={{
                    marginTop: '5px',
                    width: '1.2rem',
                    height: '1.2rem'
                }}></span></div> : <LogoutSvg />)
                }
            </button >
    </div>
  );
}

export default IndustryDashboard;

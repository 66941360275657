import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";

function InstallsAndDownloadsChart(props) {
  const newJobOpenings = [];
  const installAndDownloadsLabels = [];
  props.data?.map((item) => {
    newJobOpenings.push(item.moving_84_day_avg_num_installs);
    installAndDownloadsLabels.push(item.snapshot_date)
  });
  const [installAndDownloadsOptions, setInstallAndDownloadsOptions] = useState({
    legend: {
      show: true,
      textStyle: {
        color: "white",
      },
    },
    xAxis: {
      type: "category",
      data: installAndDownloadsLabels,
      axisLabel: {
        color: "#77778e",  
        rotate: 28, 
        // interval: 60,
        fontSize: 11,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
          pixelRatio: 2,
          backgroundColor: '#32323e',
        },
      },
    },
    animation: false,
    yAxis: [
      {
        splitLine: {
          show: true,
          lineStyle: {
            color: "rgba(80, 80, 80, 0.8)",
            width: 1,
          },
        },
      },
      {
        splitLine: {
          show: false,
        },
      },],
    series: [
      {
        name: "# of installs(84 day moving average)",
        data: newJobOpenings,
        type: "line",
        yAxisIndex: 0,
        lineStyle: {
          color: 'rgb(22, 202, 242)',
        },
        symbol: 'none',
        color: 'rgb(22, 202, 242)',
      },
      {},
    ],
  });
  useEffect(() => {
    setInstallAndDownloadsOptions({...installAndDownloadsOptions});
  }, []);
  return (
      <ReactEcharts option={installAndDownloadsOptions} style={{ height: '400%', width: '100%', }} />
  );
}

export default InstallsAndDownloadsChart;
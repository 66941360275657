import React, { useState, useEffect } from "react";
import { css } from "@emotion/react";
import { Card } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "./News.css";
import BounceLoader from "react-spinners/BounceLoader";
import { NewsDataList } from "../../services/newsDataService.js";

const override = css`
  display: block;
  margin: 0 auto;
`;

function NewsTable(props) {
  const [newsData,setNewsData] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const flterNewsData = (value) => {
    const lowerCaseValue = value.toLowerCase();
    const filteredAlerts = newsData.filter((row) => {
      if (
        row.title.toLowerCase().includes(lowerCaseValue) ||
        row.description.toLowerCase().includes(lowerCaseValue) ||
        row.date.toLowerCase().includes(lowerCaseValue)
      ) {
        return row;
      }
    });
    setFilteredData(filteredAlerts);
  };

  let columns = [
    {
      placeholder: "News Data",
      dataField: "company_ticker",
      classes: "id-custom-cell2",
      formatter: (value, row) => {
        return (
          <div className="list-f-inner">
            <div className="tech">
            <a href={row.url} style={{textDecoration: 'none'}} target="_blank">{(row.date).slice(0,10)}</a>
            </div>
            <div className="tech_">
            <a href={row.url} style={{textDecoration: 'none',color:'white'}} target="_blank">
              <h5>{row.title}</h5>
              </a>
              <div>
              {row.description}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  
  useEffect(() => {
    const accessToken = window.localStorage.getItem("accessToken");
    if (!accessToken) {
      window.location.href = "/";
    }else{
      setLoading(true);
        NewsDataList()
        .then((res) => {
          setNewsData(res.data);
          setLoading(false);
        })
        .catch((error)=>{
          console.log("error",error);
        })
    }
  },[]);

  const pagination = paginationFactory({
    page: 1,
    showTotal: true,
    sizePerPage: 5,
  });
  const rowStyle = (row, rowIndex) => {
    return { border: "" };
  };

  return (
    <div style={{ marginTop: 30 }}>
      <Card className="box">
        <Card.Title>
          <h2
            className="heading"
            style={{ marginBottom: "-0.5%", marginLeft: "8px" }}
          >
            News
          </h2>
        </Card.Title>
        {isLoading && (
          <BounceLoader color={"#36D7B7"} css={override} size={50} />
        )}
        {!isLoading && (
          <div>
            <input
              type="text"
              className="filterinput"
              placeholder="Filter News Data"
              onChange={(e) => flterNewsData(e.target.value)}
            />
            <BootstrapTable
              key="newsTable"
              id="newsTable"
              name="newsTable"
              keyField="id"
              data={filteredData === null ? newsData : filteredData }
              columns={columns}
              pagination={pagination}
              sort={{ dataField: "date", order: "desc" }}
              rowClasses="news-table-data"
              // filter={filterFactory()}
              rowStyle={rowStyle}
            />
          </div>
        )}
      </Card>
    </div>
  );
}

export default NewsTable;

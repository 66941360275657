import { useState } from "react";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { userLogin } from "./../../services/authService.js";
import "./Auth.css";
import AES from "crypto-js/aes";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignIn = (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);
    const credential = {
      uname: email,
      password: password,
    };
  
    userLogin(credential)
      .then((response) => {
        const resData = response.data;
        if (
          (resData?.USER_STATUS === "TRIAL" &&
            resData?.is_trial_expired === "YES") ||
          resData?.message === "API_KEY_EXPIRED"
        ) {
          location.href = "/expired";
        }
        if (resData.status === "KO") {
          if (resData.message === "FORCE_TO_CHANGE_PASSWORD") {
            if (typeof window !== "undefined") {
              window.localStorage.setItem("accessToken", resData.access_token);
              window.localStorage.setItem(
                "revelera_user",
                JSON.stringify({
                  email: email,
                  user_status: resData?.USER_STATUS,
                })
              );
              const ciphertext = AES.encrypt(
                email,
                process.env.REACT_APP_USER_RESET_KEY
              ).toString();
              window.localStorage.setItem("user_reset_value", ciphertext);
            }
            location.href = "/reset-password";
          } else {
            toast(resData.message);
            setError(resData.message);
          }
        } else {
          if (typeof window !== "undefined") {
            window.localStorage.setItem("accessToken", resData.access_token);
            window.localStorage.setItem(
              "revelera_user",
              JSON.stringify({
                email: email,
                user_status: resData?.USER_STATUS,
              })
            );
            (function () {
              toast("Login Successfully");
            })();
            location.href = "/home";
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          const errorMessage = error.response.data.detail;
          if (errorMessage === "CSRF Failed: CSRF token missing.") {
            toast("Please logout from the admin panel");
          }
        } else {
          console.error(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  
  return (
    <div className="App">
      <div className="auth-wrapper">
        <div className="auth-inner">
          <form onSubmit={(e) => handleSignIn(e)}>
            <h3>Sign  In</h3>
            <div className="form-group">
              <label>Email address</label>
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Enter email"
                value={email}
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="Enter password"
                value={password}
              />
            </div>
            {loading ? (
              <button
                type="submit"
                disabled
                className="btn btn-primary btn-block form-control mt-3 border-radius-25"
              >
                loading...
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-primary btn-block form-control mt-3 border-radius-25"
              >
                Login
              </button>
            )}
            <p className="forgot-password text-right d-none">
              Forgot <a href="#">password?</a>
            </p>
            {error.length !== "" && (
              <div className="text-center text-danger">{error}</div>
            )}
          </form>
          <div className="forget-password-link-container">
            <Link className="forget-password-link" to="/forgot-password">
              Forgot Password
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;

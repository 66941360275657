import React, { useEffect, useState } from 'react';
import Navbar from './../Navbar/Navbar';
import JobsTable from '../HistoricTable/JobsTable';
import Watchlist from '../Watchlist/Watchlist';
import { Row, Col } from 'react-bootstrap';
import './Dashboard.css';
import { ReactComponent as LogoutSvg } from './../../assets/images/svgs/logout.svg';
import { useSelector, useDispatch } from 'react-redux';
import Jobs from './../Jobs/Jobs';
import { userLogout } from '../../services/authService';

function Dashboard(props) {
    const [isShow, setIsShow] = useState(false)
    const dispatch = useDispatch();
    const { watchlist } = useSelector((state) => state);
    const handleLogout = () => {
        setIsShow(true)
        userLogout();
    };

    const watchListAdded = (el) => {
        dispatch({
            type: 'WATCHLIST',
            watchlist: [
                ...watchlist,
                {
                    company_name: el.name,
                    company_ticker: el.ticker,
                },
            ],
        });
    };

    useEffect(() => {
        dispatch({ type: 'SEARCH_KEY', searchkeyType: 'jobs' });
    }, []);

    return (
        <div className="h-100">
            <Navbar watchListAdded={(el) => watchListAdded(el)} />
            <Row className="aap-main-content">
                <Col lg={8}>
                    <Jobs initialLoading={true} />
                    <JobsTable
                        id="jobsTable"
                        name="jobsTable"
                        key="jobsTable"
                        initialLoading={true}
                    />
                </Col>
                <Col lg={4}>
                    <Watchlist />
                </Col>
            </Row>
            <button id="logout" className="logout-btn" onClick={() => handleLogout()}>
                {(isShow ? <div style={{height: '30px', width: '30px'}}><span className="spinner-border" role="status" aria-hidden="true" style={{
                    marginTop: '5px',
                    width: '1.2rem',
                    height: '1.2rem'
                }}></span></div> : <LogoutSvg />)
                }
            </button >
        </div >
    );
}

export default Dashboard;

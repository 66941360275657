import React from "react";
import { Toaster } from "react-hot-toast";
import JobDashboard from "./containers/Dashboard/JobDashboard";
import SkillDashboard from "./containers/Dashboard/SkillDashboard";
import SaasDashboard from "./containers/Dashboard/SaasDashboard";
import AlertDashboard from "./containers/Dashboard/AlertDashboard";
import NoMatch from "./components/NoMatch/NoMatch";
import Docs from "./containers/Docs/Docs";
import Login from "./containers/Auth/Login";
import ForgotPassword from "./containers/Auth/ForgetPassword/ForgotPassword";
import ResetPassword from "./containers/Auth/ResetPassword";
import Home from "./containers/Home/Home";
import Expired from "./components/Expired/Expired";
import { useSelector } from "react-redux";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import IndustryDashboard from "./containers/Dashboard/IndustryDashboard";
import IndicesDashboard from "./containers/Dashboard/IndicesDashboard";
import NewsDashboard from "./containers/Dashboard/NewsDashboard";
import UpworkDashboard from "./containers/Dashboard/UpworkDashboard";
import InstallsAndDownloadsDashboard from "./containers/Dashboard/InstallsAndDownloadsDashboard";

function App(props) {
  const state = useSelector((state) => state);

  const accessToken = window.localStorage.getItem("accessToken");
  if (!accessToken) {
    const authUrls = ["docs", "home"];
    const pathname = location.pathname.replaceAll("/", "");
    if (authUrls.indexOf(pathname) > -1) {
      location.href = "/404";
    }
  }

  return (
    <div className="App container-fluid h-100">
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path="/"
            element={accessToken ? <Navigate to="/jobs" replace /> : <Home />}
          />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/forgot-password" element={<ForgotPassword />} />
          <Route exact path="/reset-password" element={<ResetPassword />} />
          <Route exact path="/home" element={<Navigate to="/jobs" replace />} />
          <Route exact path="/jobs" element={<JobDashboard />} />
          <Route exact path="/skills" element={<SkillDashboard />} />
          <Route exact path="/saas" element={<SaasDashboard />} />
          <Route exact path="/macro" element={<IndustryDashboard />} />
          <Route exact path="/alerts" element={<AlertDashboard />} />
          <Route exact path="/docs" element={<Docs />} />
          <Route exact path="/404" element={<NoMatch />} />
          <Route exact path="/expired" element={<Expired />} />
          <Route exact path="/indices-view" element={<IndicesDashboard />} />
          <Route exact path="/upwork" element={<UpworkDashboard />} />
          <Route exact path="/installs-downloads" element={<InstallsAndDownloadsDashboard />} />
          <Route exact path="/news" element={<NewsDashboard />} />
        </Routes>
      </BrowserRouter>
      <Toaster />
    </div>
  );
}

export default App;

import axios from "axios";

const NewsDataList = () => {
  const accessToken = window.localStorage.getItem("accessToken");
  return axios({
    method: "get",
    url: process.env.REACT_APP_SERVER_API + `api/news`,
    headers: {
      Authorization: `Token ${accessToken}`,
      "Content-Type": "application/json", // Set content type if required by your API
    },
    responseType: "json",
  });
};

export { NewsDataList };

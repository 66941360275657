import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import BounceLoader from 'react-spinners/BounceLoader';
import { Card } from 'react-bootstrap';
import './skills.css';
import {
    getJobSkillTrends,
} from './../../services/skillDataService.js';

import { reloadIfTokenExpire, defaultSkillSearchValue } from './../../services/common.js';
import { useSelector, useDispatch } from 'react-redux';
import ReactEcharts from "echarts-for-react";


const override = css`
    display: block;
    margin: 0 auto;
`;
const eChartOption = {
    legend: {
      show: true,
      textStyle: {
        color: "white",
      },
    },
    xAxis: {
      type: "category",
      data: [],
      axisLabel: {
        color: "#77778e",  
        rotate: 28, 
        // interval: 60,
        fontSize: 11,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {
          show: true,
          title: "Save As Image",
          pixelRatio: 2,
          backgroundColor: '#32323e',
        },
      },
    },
    animation: false,
    yAxis: [
        {
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(80, 80, 80, 0.8)",
              width: 1,
            },
          },
        },
        {
            splitLine: {
                show: false,
              },
        },
      ],
    series: [
      {
        name: "",
        data: [],
        type: "line",
        yAxisIndex: 0,
        lineStyle: {
            color: 'rgb(22, 202, 242)',
          },
        symbol : 'none',
        color: 'rgb(22, 202, 242)',
      },
      {
        name: "",
        data: [],
        type: "line",
        yAxisIndex: 1,
        lineStyle: {
            color: 'rgb(255, 255, 0)',
          },
        symbol : 'none',
        color: 'rgb(255, 255, 0)',
      },
    ],
  };
function Skills(props) {
    const dispatch = useDispatch();
    const { skillTrends, skillSearchValue, suggestionsList } = useSelector((s) => s);
    const filterParam = { name: skillSearchValue, type: 'skills' };
    const [skillsData, setSkillsData] = useState({...eChartOption});
    const [loading, setLoading] = useState(false);
    const [wait, setWait] = useState(true);
    const [initialLoading, setInitialLoading] = useState(props.initialLoading);

    const callSkillsData = (searchkeyValue1) => {
        filterParam.name = searchkeyValue1;
        setLoading(true);
        getJobSkillTrends(filterParam).then(function (response) {
            dispatch({ type: 'SKILL_TRENDS', skillTrends: response.data });
            setLoading(false);
        }).catch((error)=>{
          reloadIfTokenExpire(error.response.data);
        })
    };
    useEffect(() => {
        setLoading(true);
        if (skillSearchValue && !initialLoading)
            callSkillsData(skillSearchValue);
        setInitialLoading(false);
    }, [skillSearchValue]);

    const dispatchSkillSearchValue = (dValue) => {
        dispatch({
            type: 'SKILL_SEARCH_VALUE',
            skillSearchValue: dValue,
            // skillSearchValue: defaultSkillSearchValue,
        });
    }

    useEffect(() => {
        if (suggestionsList.length) {
            for (let i = 0; i < suggestionsList.length; i++) {
              const element = suggestionsList[i];   
              // Trial User
              if (element.type === "skills" && element.trialAccess === true) {
                dispatchSkillSearchValue(element.name);
                setLoading(false)
                break;
              }
              // Regular User
              if (element.type === "skills" && element.trialAccess === 0 && element.isTrial === false) {
                dispatchSkillSearchValue(defaultSkillSearchValue);
                setLoading(false)
                break;
              }
            }
          }
    }, [suggestionsList]);

    useEffect(() => {
        const accessToken = window.localStorage.getItem("accessToken");
        if (!accessToken) {
          window.location.href = '/'
        }
        if (Object.keys(skillTrends).length) {
            reloadIfTokenExpire(skillTrends);
            let jobsDates;
            let skillsData = {...eChartOption}
            skillsData.series[0].name = '% of companies (84 day moving average)';
            skillsData.series[1].name = '% of job openings (84 day moving average)';
            const keys = Object.keys(skillTrends);

            jobsDates = keys;
            skillsData.xAxis.data = jobsDates;
            skillsData.series[0].data = keys.map((e) => {
                if (
                    skillTrends[e]['% of companies (84 day moving average)'] ||
                    0
                ) {
                    return skillTrends[e][
                        '% of companies (84 day moving average)'
                    ].toFixed(4);
                }
                return 0;
            });
            // skillsData.series[0].data = skillsData.series[0].data.filter((e) => e > 0);
            skillsData.series[1].data = keys.map((e) => {
                if (
                    skillTrends[e][
                        '% of job openings (84 day moving average)'
                    ] ||
                    0
                ) {
                    return skillTrends[e][
                        '% of job openings (84 day moving average)'
                    ].toFixed(4);
                }
                return 0;
            });
            // skillsData.series[1].data = skillsData.series[1].data.filter((e) => e > 0);
            setSkillsData(skillsData);
        }
    }, [skillTrends]);

    setTimeout(() => {
        setWait(false);
    }, 100);

    return (
        <div>
            <Card className="box">
                <Card.Body>
                    <Card.Title>
                        <div className="skill-data-header-wrapper">
                            <h2 className="heading">
                                {skillSearchValue && (
                                    <div>
                                        <span className="text-capitalize">
                                            {skillSearchValue}
                                        </span>
                                        <div className="text-muted xs-small">
                                            % of companies mentioning the skill
                                            in a job opening per day (84 day
                                            moving avg)
                                        </div>
                                    </div>
                                )}
                            </h2>
                        </div>
                    </Card.Title>

                    <div className="skill-data-chart">
                        {(loading || wait) && (
                            <BounceLoader
                                color={'#36D7B7'}
                                css={override}
                                size={50}
                            />
                        )}
                        {!loading && !wait && (
                            <div style={{ width: "100%", height: "400px" }}>
                             <ReactEcharts option={skillsData} style={{ height: '100%', width: '100%', }} />
                            </div>
                        )}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Skills;
